'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import bg from '@/public/staticfiles/banner/bannerbg-min.webp';
import logo from '@/public/staticfiles/logo/logo_short_white.svg?url';
import { useAnalyticsContext } from '@/processes/analytics';
import { Button, Container } from '@/shared/ui';
import { Title, Text, ButtonsWrapper, Content, Wrapper } from './styled';
import { AppstoreIcon, GplayIcon } from '@/shared/ui/Icon/svg';

function AppBanner() {
  const t = useTranslations();
  const { sendSafeYMEvent, sendSafeGtagEvent } = useAnalyticsContext();

  return (
    <Wrapper>
      <Image src={bg} alt="bg" width={400} height={200} />
      <Container>
        <Image height={56} src={logo} alt="logo" />
        <Content>
          <Title>{t('banner.banner_title')}</Title>
          <Text>{t('banner.banner_text')}</Text>
        </Content>
        <ButtonsWrapper>
          <Button
            as="a"
            target="_blank"
            rel="noreferrer nofollow"
            href="https://apps.apple.com/app/apple-store/id1608870673?pt=119567154&ct=web3_v1&mt=8"
            label={<AppstoreIcon />}
            onClick={() => {
              sendSafeGtagEvent('appstore_click');
              sendSafeYMEvent('app_store_click');
            }}
          />
          <Button
            as="a"
            target="_blank"
            rel="noreferrer nofollow"
            href="https://play.google.com/store/apps/details?id=app.nicegram&utm_source=nicegram.app&utm_medium=main&utm_campaign=web&pli=1"
            label={<GplayIcon />}
            onClick={() => {
              sendSafeGtagEvent('gplay_click');
              sendSafeYMEvent('app_store_click');
            }}
          />
        </ButtonsWrapper>
      </Container>
    </Wrapper>
  );
}

export { AppBanner };
